// 防抖
function debounce(cb, wait = 1000, immediate = false) {
    if(!cb || 'function' !== typeof cb) throw new TypeError('cb不能为空或cb必须是一个函数');
    let timer = null;
    return function() {
        if(timer) clearTimeout(timer);
        if(immediate) {
            if(!timer) cb.apply(this, arguments);
            timer = setTimeout(() => {
                cb.apply(this, arguments);
                clearTimeout(timer);
                timer = null;
            }, wait);
        } else {
            timer = setTimeout(() => {
                cb.apply(this, arguments);
                clearTimeout(timer);
                timer = null;
            }, wait);
        }
    }
}

$(function(){
    +function(){
        let dex = getUrlSearch('index')==null?0:getUrlSearch('index'),
                type = getUrlSearch('type2')==null?1:getUrlSearch('type2'),
                type_id = getUrlSearch('type_id')==null?0:getUrlSearch('type_id');
        // 2021/12/10 注释classify 因为搜索不需要了
        // $(document).on('click',function(){
        //     $('#classify').hide()
        // })
        // $('#classify').on('click','li',function(){
        //     $(this).addClass('actived').siblings().removeClass('actived')
        //     $('#classify').hide();
        //     $(this).parent().siblings('span').html($(this).text()).data('type',$(this).data('type')).data('type_id',$(this).data('type_id'))
        //     history.replaceState(null,null,'?index='+$(this).index()+'&type2='+$(this).data('type')+'&type_id='+$(this).data('type_id'))
        //     dex=$(this).index();
        //     type=$(this).data('type');
        //     type_id = $(this).data('type_id');
        // })
        // $.ajax({
        //     type: 'post',
        //     url: jkas.url('/wxxcx/searchTipsType'),
        //     dataType: 'json',
        //     async:false,
        //     success: function (res) {
        //         if (res.code == 10) {
        //             if(dex!='null'&&dex!=null){
        //                 $('#classify').siblings('span').html(res.list[dex].search_name)
        //                 $('#count').siblings().text(res.list[dex].search_name)
        //                 $('#goods-box').children('.no_data').children('p').text('没有找到符合条件的'+res.list[dex].search_name)
        //             }else{
        //                 $('#classify').siblings('span').html(res.list[0].search_name)
        //             }
        //             var htm='';
        //             for(var i=0;i<res.list.length;i++){
        //                 let str =  dex==i?'actived':'';
        //                 htm += '<li class="'+str+'" data-type="'+res.list[i].search_type+'" data-type_id="'+res.list[i].search_type_id+'">'+res.list[i].search_name+'</li>';
        //             }
        //             $('#classify').append(htm)
        //         } else {
        //             layer.msg(res.message)
        //         }
        //     }
        // })
        // $('.search_type span').click(function(){
        //     $(this).addClass('act').siblings().removeClass('act');
        //     if($(this).hasClass('shop')){
        //         like_type=2;
        //         $("#keywords").attr('placeholder','搜索店铺名称');
        //     }else if($(this).hasClass('goods')){
        //         like_type=1;
        //         $("#keywords").attr('placeholder','搜索商品名称');
        //     }
        // })
        // for(var i=0;i<$('.search_type span').length;i++){
        //     if($('.search_type span').eq(i).hasClass('act')){
        //         if($('.search_type span').eq(i).hasClass('shop')){
        //             like_type=2
        //         }else if($('.search_type span').eq(i).hasClass('goods')){
        //             like_type=1
        //         }
        //     }
        // }
        $('.search_r').click(function(e){
            e.preventDefault();
            urlsearch();
        })
        $('input[name=keywords]').on('keypress',function(event){
            if(event.keyCode == 13) 
            {  
                urlsearch();
            }
        })
        function urlsearch(){
            var keywords = $('#keywords').val();
            var tabidx = $('input[name=tabidx]').val() || '0';
            var type = $('input[name=type]').val() || '1';
            keywords = encodeURIComponent(keywords.trim());
            location.href = `/search/idx.htm?keywords=${keywords}&index=${dex}&type2=${type}&type_id=${type_id}&tabidx=${tabidx}&type=${type}`
            // location.href = jkas.url("/search/idx")+"?keywords=" + encodeURIComponent(keywords)+'&index='+dex+'&type2='+type+'&type_id='+type_id
        }
        $('#keywords').on('input propertychange',debounce(function(){
            var keywords = $(this).val();
            var tabidx = $('input[name=tabidx]').val() || '0';
            var type = $('input[name=type]').val() || '1';
            $('.search_res_box').show();
            $.ajax({
                type:'post',
                url:jkas.url('/search/fuzzy'),
                data:{
                    keywords: keywords.trim(),
                    search_type: ~~tabidx + 1,
                    search_type_id:type_id
                },
                dataType:'json',
                success:function(res){
                    var html = ''
                    if(res.data.length>0){
                        for(var i=0;i<res.data.length;i++){
                            let key = 
                            html+="<li class='txtof'><a href=\"" + jkas.url('/search/idx')+'?index='+dex+'&type2='+type+'&type_id='+type_id+'&keywords='+encodeURIComponent(res.data[i].search_name)  + '&tabidx=' + tabidx + '&type=' +type+ "\">" + res.data[i].search_name +"</a></li>";
                        }
                    }else{
                        html="<div class='nodata'>没有搜索结果...</div>"
                    }
                    $('.search_res_box ul').html(html);
                }
            })
        }, 500)).on('blur',function(){
            setTimeout(function(){
                $('.search_res_box').hide();
            },150)
        });
    }();
})